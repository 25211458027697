@use 'style';

* {
    padding: 0;
    margin: 0;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    font-family: var(--ff);
    font-size: 16px;
    line-height: 24px;
    color: style.$colorDescription;
    background-color: style.$colorBG;
    background-image: url(./../assets/images/bg.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    border: none;
    background: transparent;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
    color: style.$colorLogo;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
    color: style.$colorLogo;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    display: block;
}

article > * + * {
    margin-top: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
        -webkit-transition-duration: 0.01ms !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

html {
    scrollbar-color: style.$colorMain style.$colorBG !important;
    scrollbar-width: thin !important;
}

::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: style.$colorMain;
    border-radius: 15px;
    transition: all 0.3s ease-in-out 0;
    width: 5px;
}
::-webkit-scrollbar-thumb:hover {
    transition: all 0.3s ease-in-out 0;
    background: rgba(style.$colorMain, 0.8);
}
